import { createContext, useContext, useEffect, useRef, useState } from "react";
import LogoLoading from "./components/LogoLoading";
import { BASE_URL, HEADER_JSON } from "./config/backendCfg";
import { useStorage, useStorageUpdate } from "./StorageContext";
import { handleFetchResponse } from "./utilities/utilities";

const ConfirmationsContext = createContext();
const RegionsContext = createContext();
const PaymentContext = createContext();
const GeneralData = createContext();
const CartContext = createContext();

// User context
export const UserContext = createContext();

export function usePayment() {
  return useContext(PaymentContext);
}
export function useConfirmations() {
  return useContext(ConfirmationsContext);
}
export function useRegions() {
  return useContext(RegionsContext);
}
export function useGeneralData() {
  return useContext(GeneralData);
}
export function useCart() {
  return useContext(CartContext);
}

export function DataProvider(props) {
  const storage = useStorage();
  const updateStorage = useStorageUpdate();
  const [regions, setRegions] = useState(null);

  const [user, setUser] = useState(); // null | user data

  useEffect(() => {
    if (!storage.userId || !storage.token) return;

    fetch(`${BASE_URL}/user/id/${storage.userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: storage.token,
      },
    })
      .then(handleFetchResponse)
      .then((data) => {
        setUser(data);
      })
      .catch((error) => {
        setUser(null);
      });
  }, [storage.token, storage.userId]);

  function logout() {
    setUser(null);
    localStorage.clear();
    updateStorage();
  }

  // null for loading, false for no login, array for confirmations
  const [confirmations, setConfirmations] = useState(null);
  const [payment, setPayment] = useState(
    JSON.parse(localStorage.getItem("orderPayment")) || {
      scheduling: "deposit",
    }
  );

  const [cart, setCart] = useState({ loading: true });

  useEffect(() => {
    fetch(`${BASE_URL}/general/region`, {
      method: "GET",
      headers: HEADER_JSON,
    })
      .then((response) => response.json())
      .then((data) => {
        setRegions(data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  useEffect(() => {
    if (!storage.token) return;
    updateCart();
  }, [storage.token]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Manipulate cart
   * @param {string} action - reload/add/remove/coupon
   * * Add
   * @param {object} data - data to add/remove
   * @param {number} data.date - event date
   * @param {object} data.guests - event guests
   * @param {string} [data.venue] - venue ID
   * @param {string} [data.experience] - experience ID
   * @param {string} [data.packageData] - package Data
   * @param {string} [data.packageData.id] - package ID
   * @param {string} [data.packageData.vendor] - vendor ID
   * @param {string} [data.packageData.role] - vendor role
   * * Remove
   * @param {string} [data.remove] - remove data
   * @param {string} [data.remove.type] - packages/experience/venue/all
   * @param {string} [data.remove.id] - remove ID
   */
  async function updateCart(type = "reload", data = null) {
    if (!storage.token) {
      throw new Error("You need to sign in");
    }
    setCart((prev) => ({ ...prev, loading: true }));
    let res;
    if (type === "reload") {
      res = await fetch(`${BASE_URL}/cart`, {
        method: "GET",
        headers: { ...HEADER_JSON, Authorization: storage.token },
      });
    } else if (type === "add") {
      const body = {
        date: typeof data.date === "number" ? data.date : data.date.getTime(),
        guests: data.guests,
      };
      if (data.experience) {
        body.experience = data.experience;
      } else if (data.packageData) {
        body.package = {
          package: data.packageData.package,
          user: data.packageData.vendor,
          role: data.packageData.role,
        };
      }
      if (data.venue) body.venue = data.venue;
      res = await fetch(`${BASE_URL}/cart/${storage.userId}`, {
        method: "PUT",
        headers: { ...HEADER_JSON, Authorization: storage.token },
        body: JSON.stringify(body),
      });
    } else if (type === "remove") {
      res = await fetch(
        `${BASE_URL}/cart/${storage.userId}/${data.remove.type}${
          data.remove.type === "packages" ? `?packageId=${data.remove.id}` : ""
        }`,
        {
          method: "DELETE",
          headers: { ...HEADER_JSON, Authorization: storage.token },
        }
      );
    } else if (type === "coupon") {
      res = await fetch(`${BASE_URL}/cart/${storage.userId}/coupon`, {
        method: "PUT",
        headers: { ...HEADER_JSON, Authorization: storage.token },
        body: JSON.stringify({ code: data.code }),
      });
    }
    if (res.ok) {
      const data = await res.json();
      data.loading = false;
      setCart(data);
      return true;
    } else {
      setCart((prev) => ({ ...prev, loading: false }));
      return res;
    }
  }

  const _confirmationsTimeout = useRef(null);
  useEffect(() => {
    if (!storage.token) {
      setConfirmations(false);
      return;
    }
    const requestOptions = {
      method: "GET",
      headers: { ...HEADER_JSON, Authorization: storage.token },
    };
    fetch(`${BASE_URL}/confirmation`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setConfirmations(data);
      })
      .catch((error) => console.error("Error:", error));
    _confirmationsTimeout.current = setInterval(() => {
      fetch(`${BASE_URL}/confirmation`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setConfirmations(data);
        })
        .catch((error) => console.error("Error:", error));
    }, 30000);

    return () => {
      if (_confirmationsTimeout.current) {
        clearInterval(_confirmationsTimeout.current);
        _confirmationsTimeout.current = null;
      }
    };
  }, [storage.token]);

  function updatePayment(type, value = null) {
    if (type === "clear") {
      localStorage.removeItem("orderPayment");
      setPayment({ scheduling: "deposit" });
      return;
    } else {
      localStorage.setItem(
        "orderPayment",
        JSON.stringify({ ...payment, [type]: value })
      );
      setPayment({ ...payment, [type]: value });
    }
  }

  return (
    <RegionsContext.Provider value={regions}>
      <UserContext.Provider value={{ user, logout }}>
        <PaymentContext.Provider value={[payment, updatePayment]}>
          <CartContext.Provider value={[cart, updateCart]}>
            <ConfirmationsContext.Provider value={confirmations}>
              {regions ? (
                props.children
              ) : (
                <div className="w-full h-screen flex flex-col items-center justify-center text-text">
                  <LogoLoading />
                  <span className="mt-2">Loading...</span>
                </div>
              )}
            </ConfirmationsContext.Provider>
          </CartContext.Provider>
        </PaymentContext.Provider>
      </UserContext.Provider>
    </RegionsContext.Provider>
  );
}
