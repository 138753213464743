import { TIME_TO_EVENT } from "../config/frontendCfg";

export function createEmptyTable(rowCount, columnCount, value) {
  var table = [];
  for (var i = 0; i < rowCount; i++) {
    table[i] = [];
    for (var j = 0; j < columnCount; j++) {
      table[i][j] = value;
    }
  }
  return table;
}

export function createTimeSlots(startingHour, endingHour, length) {
  //length is in minutes
  let arr = [];
  for (
    let index = startingHour * 60;
    index < endingHour * 60;
    index += length
  ) {
    let h = Math.floor(index / 60);
    let m = index % 60;
    if (h < 10) arr.push("0" + h + ":0" + m);
    else arr.push(h + ":0" + m);
  }
  return arr;
}

export function hashCode(str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return (
    (h2 >>> 0).toString(16).padStart(8, 0) +
    (h1 >>> 0).toString(16).padStart(8, 0)
  );
}

export function handleFetchResponse(res) {
  if (res.ok) return res.json();
  else return new Promise.reject(res);
}

export function isValidEventDate(date) {
  return (
    date instanceof Date &&
    !isNaN(date) &&
    date.getTime() > new Date().getTime() + TIME_TO_EVENT &&
    date.getTime() % 1_800_000 === 0
  );
}

export function findSameWeekDayTime(dateArray, date) {
  const eventDate = date.getDay();
  const eventHour = date.getHours();
  const eventMinute = date.getMinutes();
  return dateArray.find((d) => {
    return (
      d.getDay() === eventDate &&
      d.getHours() === eventHour &&
      d.getMinutes() === eventMinute
    );
  });
}

/**
 * Returns if the vendor is available at the given date
 * @param {Array} availableDates - Array of Date objects generated by getAvailableDates
 * @param {Object} schedule
 * @param {Object} schedule.exceptions - Array of exceptions
 * @param {Object} schedule.booked - Array of exceptions
 * @param {Object} date - Date object
 * @returns {Boolean} - True if date is available
 */
export function isScheduleAvailable(availableDates, schedule, date) {
  if (isValidEventDate(date) && findSameWeekDayTime(availableDates, date)) {
    if (
      schedule.exceptions.find(
        (d) =>
          new Date(d.from).getTime() <= date.getTime() &&
          new Date(d.to).getTime() >= date.getTime()
      )
    ) {
      return false;
    }
    // if (
    //   schedule.booked
    //     .map((d) => {
    //       let dateTime = new Date(d);
    //       dateTime.setTime(dateTime.getTime() + 1000 * 60 * 180);
    //       console.log([new Date(d), dateTime]);
    //       return [new Date(d), dateTime];
    //     })
    //     .find((d) => d.getTime() === date.getTime())
    // ) {
    //   return false;
    // }
    return true;
  } else {
    return false;
  }
}

/**
 * Returns an array of available dates
 * @param {Object} schedule - Schedule object
 * @returns {Array} - Array of Date objects
 */
export function getAvailableDates(schedule) {
  const availableDates = [];
  let tempDate = [];

  if (!schedule?.schedule || schedule?.schedule.length === 0) return [];
  schedule.schedule
    .map((s) => new Date(s))
    .sort((a, b) => (a.getTime() > b.getTime() ? 1 : -1))
    .forEach((s) => {
      const time = s.getTime();

      if (tempDate.length === 0) {
        tempDate.push(s);
      } else {
        const lastDate = tempDate[tempDate.length - 1];
        if (time === lastDate.getTime() + 30 * 60 * 1000) {
          tempDate.push(s);
        } else {
          tempDate = [s];
        }

        if (tempDate.length === 6) {
          availableDates.push(tempDate[0]);
          tempDate.shift();
        }
      }
    });

  return availableDates;
}

export async function reqErrorHandler(err) {
  let response = {};
  const data = await err.json();
  if (data.validation && data.validation.length > 0) {
    response.type = "validation";
    let errors = [];
    data.validation.forEach((e) => {
      errors.push(e);
    });
    response.errors = errors;
  } else {
    response.type = "error";
    response.message = data.message;
  }
  return response;
}

export function getRoles(roles) {
  let userRoles = [];
  for (let key in roles) {
    if (key !== "data" && roles[key]) {
      userRoles.push(key);
    }
  }
  return userRoles;
}

export function hasRoleAccess(userRoles, roles) {
  let hasAccess = false;
  if (Array.isArray(userRoles)) {
    userRoles.forEach((role) => {
      if (roles.some((r) => r === role)) {
        hasAccess = true;
      }
    });
  } else {
    for (let key in userRoles) {
      if (key !== "data" && userRoles[key] && roles.some((r) => r === key)) {
        hasAccess = true;
        break;
      }
    }
  }
  return hasAccess;
}

export function getPastTimeAsString(date) {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  if (years > 0) {
    return `${years} years ago`;
  } else if (months > 0) {
    return `${months} months ago`;
  } else if (days > 0) {
    return `${days} days ago`;
  } else if (hours > 0) {
    return `${hours} hours ago`;
  } else if (minutes > 0) {
    return `${minutes} minutes ago`;
  } else if (seconds > 0) {
    return `${seconds} seconds ago`;
  } else {
    return "Just now";
  }
}

export function getRemainingTimeAsString(date) {
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  if (years > 0) {
    return `${years} years`;
  } else if (months > 0) {
    return `${months} months`;
  } else if (days > 0) {
    return `${days} days`;
  } else if (hours > 0) {
    return `${hours} hours`;
  } else if (minutes > 0) {
    return `${minutes} minutes`;
  } else if (seconds > 0) {
    return `${seconds} seconds`;
  } else {
    return "Just now";
  }
}

export function handleScrollX(elementId, direction, fraction = 2) {
  let amount = window.innerWidth / fraction;
  if (direction === "left") {
    amount = -amount;
  }
  const element = document.getElementById(elementId);
  element.scrollTo(element.scrollLeft + amount, 0);
}

export function getQueryLink(link, searchParams, options) {
  if (options?.addRedirectHere) {
    let url = new URL(window.location);
    if (!url.searchParams.get("redirect")) {
      if (window.location.pathname !== "/login") {
        url.searchParams.set("redirect", window.location.pathname);
      }
    }
    return `${link}${url.search ? `${url.search}` : ""}`;
  }
  const query = searchParams.toString();
  return `${link}${query ? `?${query}` : ""}`;
}
