import { createContext, useContext, useState } from "react";

const StorageContext = createContext();
const UpdateStorageContext = createContext();

export function useStorage() {
  return useContext(StorageContext);
}

export function useStorageUpdate() {
  return useContext(UpdateStorageContext);
}

export function StorageProvider(props) {
  const [storage, setStorage] = useState(getStorageItems);

  function updateStorage() {
    setStorage(getStorageItems());
  }

  function getStorageItems() {
    const length = localStorage.length;
    if (!length) return {};
    const storage = {};
    for (let i = 0; i < length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);
      if (key === "roles") {
        storage[key] = value ? value.split(",") : [];
      } else storage[key] = value;
    }
    return storage;
  }

  return (
    <StorageContext.Provider value={storage}>
      <UpdateStorageContext.Provider value={updateStorage}>
        {props.children}
      </UpdateStorageContext.Provider>
    </StorageContext.Provider>
  );
}
