import { Client } from "@twilio/conversations";
import { createContext, useContext, useEffect, useState } from "react";
import { BASE_URL, HEADER_JSON } from "./config/backendCfg";
import { useStorage } from "./StorageContext";

const ChatTokenContext = createContext();
const ChatContext = createContext();
const ConversationsContext = createContext();

export function useChatToken() {
  return useContext(ChatTokenContext);
}
export function useChatClient() {
  return useContext(ChatContext);
}
export function useConversations() {
  return useContext(ConversationsContext);
}

export function ChatProvider({ children }) {
  const storage = useStorage();
  const [chatToken, setChatToken] = useState(
    JSON.parse(localStorage.getItem("chatToken")) || null
  );
  const [chatClient, setChatClient] = useState(null);
  const [conversations, setConversations] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem("chatToken")) {
      if (!storage.token) {
        if (chatClient) {
          chatClient.shutdown();
          setChatClient(null);
          setConversations(null);
          setChatToken(null);
        }
        return;
      }
      updateToken();
      return;
    }

    const client = new Client(chatToken);
    setChatClient(client);

    client.on("stateChanged", async (state) => {
      if (state === "initialized") {
        client.getSubscribedConversations().then((conversations) => {
          if (conversations.items) {
            // TODO: Handle more pages
            setConversations(conversations);
          } else {
            setConversations(false);
          }
        });
      }
    });
    client.on("tokenAbout", async () => {
      updateToken();
    });
    client.on("tokenExpired", async () => {
      updateToken();
    });
    client.on("connectionError", (err) => {
      if (err.errorCode === 20104) {
        updateToken();
      }
    });
  }, [chatToken, storage.token]); // eslint-disable-line react-hooks/exhaustive-deps

  // Just call the function to update the state and set the local storage
  function updateToken() {
    if (!storage.token) return;
    fetch(`${BASE_URL}/conversation/token`, {
      method: "GET",
      headers: { ...HEADER_JSON, Authorization: storage.token },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          Promise.reject(res.json());
        }
      })
      .then((data) => {
        setChatToken(data.token);
        localStorage.setItem("chatToken", JSON.stringify(data.token));
      })
      .catch((err) => {
        console.warn(err);
      });
  }

  return (
    <ChatTokenContext.Provider value={[chatToken, updateToken]}>
      <ChatContext.Provider value={chatClient}>
        <ConversationsContext.Provider value={conversations}>
          {children}
        </ConversationsContext.Provider>
      </ChatContext.Provider>
    </ChatTokenContext.Provider>
  );
}
