import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function MuiTheme(props) {
  let theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#FFF500",
      },
      dark: {
        main: "#181818",
        light: "#2B2B2B",
        dark: "#111111",
        contrastText: "#CCCCCC",
      },
      info: {
        main: "#00bfff",
      },
      background: {
        paper: "#2C2C2C",
        textField: "#222222",
        textFieldHover: "#2F2F2F",
        dark: "#1D1D1D",
      },
    },
    shape: {
      borderRadius: 4,
    },
    breakpoints: {
      values: {
        zero: 0,
        xs: 320,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1536,
      },
    },
  });
  theme = createTheme(theme, {
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            fontSize: "50px",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input, & .MuiFormLabel-root": {
              fontFamily: ["Poppins", "sans-serif"].join(","),
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundImage: "none",
            backgroundColor: "#181818",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            font: ["Poppins", "sans-serif"].join(","),
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: "0.5rem 1rem",
            fontFamily: ["Poppins", "sans-serif"].join(","),
          },
        },
        variants: [
          {
            props: { size: "large" },
            style: {
              fontWeight: 500,
              fontSize: "0.9rem",
            },
          },
          {
            props: { size: "medium" },
            style: {
              fontWeight: 700,
              fontSize: "0.7rem",
            },
          },
          {
            props: { size: "small" },
            style: {
              fontWeight: 600,
              fontSize: "0.62rem",
            },
          },
        ],
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: "0.25rem",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            backgroundColor: theme.palette.background.paper,
          },
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            "& .MuiPickersDay-root": {
              color: "#FFFFFF",
              borderRadius: "0.125rem",
              backgroundColor: "#282828",
            },
            "& .MuiPickersDay-root:not(.Mui-disabled):not(.Mui-selected):hover":
              {
                backgroundColor: "#333",
                outline: "1px solid #777777",
              },
            "& .Mui-selected": {
              color: "#222222",
              backgroundColor: theme.palette.primary.main,
            },
            "& .Mui-disabled": {
              backgroundColor: "#151515",
              color: "#777777",
            },
          },
        },
      },
      MuiRating: {
        styleOverrides: {
          root: {
            color: "#CCCCCC",
            "&:hover": {
              color: "#FFFFFF",
            },
          },
        },
      },
    },
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
  });
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
