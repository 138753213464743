import { lazy, Suspense } from "react";
import LogoLoading from "./components/LogoLoading";
import { useStorage } from "./StorageContext";

const Routes = lazy(() => import("./Routes"));
const AdminRoutes = lazy(() => import("./AdminRoutes"));

export default function Router() {
  const storage = useStorage();
  const isAdmin = Boolean(storage.userId && storage.roles.includes("admin"));

  return (
    <Suspense
      fallback={
        <div className="w-full h-screen flex flex-col items-center justify-center text-text">
          <LogoLoading />
          <span className="mt-2">Loading...</span>
        </div>
      }
    >
      {isAdmin ? <AdminRoutes /> : <Routes />}
    </Suspense>
  );
}
