export default function LogoLoading({ color, width, thickness }) {
  return (
    <svg
      width={width || "25"}
      viewBox="0 0 101 275"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="animate-loading"
    >
      <path
        d="M51.5 67C95.5 67 100.5 108 100.5 108V230.5C99.3333 234.5 94.2 241.9 83 239.5C83 232.3 83 194.833 83 177C82.3333 175.833 80.6 174.2 79 177C79 180.2 79 236.333 79 264C78.3333 267.333 74.8 274 66 274C57.2 274 53.6667 267.333 53 264V221C52.8333 220.333 52.2 219 51 219C49.8 219 49.1667 220.333 49 221V255C49 256 45.2 257 38 257C30.8 257 28.3333 249 28 245V206C27.8333 205.333 27.1 204 25.5 204C23.9 204 23.1667 205.333 23 206V228C23 229.5 18.6 230 13 230C7.4 230 3.66667 224 2.5 221V108C2.5 108 6.83878 72.422 43 67.5503M51.5 67C61 64.8333 80.5 56.6 82.5 41C84.1 27.8 82.8333 22.5 82 21.5C79.6667 23.8333 73.1 29.6 65.5 34C57.9 38.4 53.3333 45.8333 52 49V60C52.3333 60.5 53.3 61.3 54.5 60.5C56 59.5 73.5 49.5 77 36.5C77.5 40.8333 73.1 52.8 51.5 66H46C37 58 13 51 9.5 19.5C12.6667 29.9999 24.8 53.1997 48 62C49.1667 59.1667 50.8 51 48 41C45.2 31 31.5 22.5 25 19.5C20.1667 17.1667 8.9 10.3 2.5 1.5C-3 36.5 10.5 62 43 67.5503M51.5 67C48.4875 67 45.6578 67.1922 43 67.5503"
        stroke={color || "#aaaaaa"}
        strokeWidth={thickness || 2}
      />
    </svg>
  );
}
