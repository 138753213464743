import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { StorageProvider } from "./StorageContext";
import MuiTheme from "./components/MuiTheme";
import { SnackbarProvider } from "notistack";
import { AlertProvider } from "./AlertUser";
import { DataProvider } from "./DataContext";
import Router from "./Router";
import { ChatProvider } from "./ChatContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MuiTheme>
      <SnackbarProvider
        preventDuplicate
        autoHideDuration={3000}
        maxSnack={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <StorageProvider>
          {/* Access localStorage */}
          <AlertProvider>
            {/* Alert User Dialog */}
            <DataProvider>
              {/* General Data */}
              <ChatProvider>
                {/* Chat Context */}
                <Router />
              </ChatProvider>
            </DataProvider>
          </AlertProvider>
        </StorageProvider>
      </SnackbarProvider>
    </MuiTheme>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
